import React, { useMemo } from 'react'
import '@babel/polyfill'
import { ApolloProvider } from '@apollo/client'
import { ExtraErrorData } from '@sentry/integrations'
import { init, setTag } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { pdfjs } from 'react-pdf'

import { ErrorProvider, flattenMessages, getMessages, Locales } from '@acre/utils'
import { LutherException } from '@acre/graphql'
import acreConfig from '@acre/config'
import { Alert, DefaultErrorBoundary, theme, ThemeProvider } from '@acre/design-system'

import App from './App'
import initApollo from './bootstrap/client'
import messages from './intl/messagesClientPortal'

// Set the workerSrc manually because react-pdf no direct support for vite
//
// File is copied during dev & build via vite-static-copy-plugin [see apps/broker-crm/vite.config.ts]
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

const ClientPortal = () => {
  const client = useMemo(initApollo, [])
  const flattenedMessages = flattenMessages(getMessages(messages, Locales.GB))

  if (import.meta.env.MODE === 'production') {
    init({
      dsn: 'https://ee7d1ac2845d4d9a998f53da3575f68a@sentry.io/1876005',
      environment: acreConfig.SENTRY_ENVIRONMENT,
      integrations: [new BrowserTracing(), new ExtraErrorData({ depth: 5 })],
      tracesSampleRate: 0,
      // We want to silence auth 401s as they are not genuine errors - they are the result of the user being logged out
      ignoreErrors: ['Invalid authorisation cookie'],
      beforeSend(event, hint) {
        // This ensures that sentry is grouping our events as we want them, rather than putting all failing API calls together
        const error = hint?.originalException as Error

        if (error && typeof error !== 'string' && error.name === 'LutherException') {
          const err = error as LutherException

          event.fingerprint = [err.message]
        }

        return event
      },
    })
    setTag('issue_type', 'frontend')
    setTag('app', 'client-portal')
  }

  return (
    <ApolloProvider client={client}>
      <DefaultErrorBoundary>
        <IntlProvider locale={Locales.GB} messages={flattenedMessages}>
          <ThemeProvider theme={theme}>
            <ErrorProvider alertSlot={Alert}>
              <App />
            </ErrorProvider>
          </ThemeProvider>
        </IntlProvider>
      </DefaultErrorBoundary>
    </ApolloProvider>
  )
}

if (!window.Intl) {
  // @ts-ignore
  require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], (require) => {
    require('intl')
    require('intl/locale-data/jsonp/en.js')

    runApp()
  })
} else {
  runApp()
}

function runApp() {
  ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(<ClientPortal />)
}
