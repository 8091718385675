import configFlags from './configFlags'
import { createFlags, InferType } from './lib'

export enum FlagType {
  Feature = 'feature',
  Automation = 'automation',
  Untracked = 'untracked',
  Club = 'club',
}

export enum Feature {
  Fullstory = 'fullstory',
  MI = 'mi',
  HideClientList = 'hideClientList',
  NewProtectionSourcing = 'new_protection_sourcing',
  AddIntroducer = 'add_introducer',
  InviteToScheduleAppointment = 'inviteToScheduleAppointment',
  Conveyancing = 'conveyancing',
  KbaFlow = 'kbaFlow',
  Network = 'network',
  Lender = 'lender',
  BulkIntroducers = 'bulk_introducers',
  Surveying = 'surveying',
  SurveyingCp = 'surveyingCp',
  SurveysNew = 'surveys_new',
  Integrations = 'integrations',
  ReferWills = 'refer_wills',
  PreviewMode = 'preview_mode',
  NeedAPro = 'need_pro',
  MovingCompany = 'moving_company_referral',
  ConveyancingClientPortal = 'conveyancing_client_portal',
  CreditReportCp = 'credit_report_cp',
  Dashboard3Cp = 'dashboard_3_cp',
  TurnOffReportProblem = 'turn_off_report_problem',
  HomebuyersChecklist = 'homebuyers_checklist',
  AccountingSearchMode = 'accounting_search_mode',
  CaseEconomics = 'case_economics',
  ReportCommissions = 'report_commissions',
  Dashboards = 'dashboards',
  HidePredictedRevenue = 'hide_predicted_revenue',
  ShowComplianceView = 'show_compliance_view',
  Payments = 'payments',
  HideLandRegistryPurchase = 'hide_land_registry_purchase',
  CaseSidebarDrawer = 'case_sidebar_drawer',
  AddingManualGI = 'adding_manual_gi',
  StandaloneGI = 'standalone_gi',
  EnhancedAutoRefer = 'enhanced_autorefer',
  HideOutOfPanelProcs = 'hide_out_of_panel_procs',
  HomeInOne = 'homeInOne',
  PrivateMedicalInsurance = 'private_medical_insurance',
  CommissionOptions = ' commission_options',
  MultiOrgReport = 'multi_org_report',
  ComplianceDashboardNavLinks = 'compliance_dashboard_nav_links',
  ConveyancingScotland = 'conveyancing_scotland',
  AdvancedPermissions = 'advanced_permissions',
  XlsReport = 'xls_report',
  DataSecurityReport = 'data_security_report',
  AllowARToggleIncludeDirect = 'allow_ar_toggle_include_direct',
}

export enum Automation {
  InviteClient = 'invite_client',
}

export enum Untracked {
  ARIsTradingStyle = 'ar_is_trading_style',
  ARIsSubdivision = 'ar_is_subdivision',
}

export enum LenderFlag {
  Santander = 'santander',
  NatWest = 'natwest',
  Nationwide = 'nationwide',
}

export enum Club {
  PMS13 = 'pms13',
  PMSIPFA = 'pmsipfa',
  PMSEXT = 'pmsext',
  FPG = 'fpg',
  RightMortgage = 'rightmortgage',
  MMD = 'mmd',
  CONNELLS = 'connells',
}

// export type Flags = {
//   [FlagType.Feature]: {[P in keyof Feature]: Feature[P]}
//   [FlagType.Automation]: {[P in keyof Automation]: Automation[P]}
//   [FlagType.Untracked]: {[P in keyof Untracked]: Untracked[P]}
//   [FlagType.Club]: {[P in keyof Club]: Club[P]}

// }

export const featureFlagDescriptions: { [key in Feature | Automation | Club | Untracked]: string } = {
  [Feature.Fullstory]: 'Enable/Disable Fullstory analytics',
  [Feature.MI]: 'Enable/Disable viewing the reporting tabs. Please note additional permissions are required.',
  [Feature.HideClientList]: 'Enable/Disable hiding the client list. Please note additional permissions are required.',
  [Feature.NewProtectionSourcing]:
    'Enable/Disable New Protection Sourcing: Old sourcing is deprecated. No longer supported/maintained.', // Depreciated
  [Feature.AddIntroducer]: 'Enable/Disable the ability to create and modify introducer organisations.',
  [Feature.InviteToScheduleAppointment]:
    'Enable/Disable the ability invite clients to book time directly with customers.',
  [Feature.Conveyancing]: 'Enable/Disable the ability to refer clients to a conveyancer',
  [Feature.Network]: 'Enable/disable access to network mgmt functionality for DA Organisations.',
  [Feature.KbaFlow]:
    'Enable/Disable the KBA flow feature in the credit section of the fact find (Subject to Compliance Requirements)',
  [Feature.Lender]: 'Enable access to specific lender for DIP/FMA purposes',
  [Feature.BulkIntroducers]: 'Enable access to the bulk upload form for introducers',
  [Automation.InviteClient]: 'Auto-invite client to CP on case creation.',
  [Feature.Surveying]: 'Allow user to refer for surveys with SBVS (deprecated)',
  [Feature.SurveyingCp]: 'Display surveying referrals CP',
  [Feature.SurveysNew]: 'Allow user to refer for surveys with Acre Surveying on Broker CRM and CP',
  [Feature.Integrations]: 'Enable access to create and manage oauth users and apps (Zapier etc)',
  [Feature.ReferWills]: 'Enable/Disable referral for wills in the client portal',
  [Feature.PreviewMode]: 'Enable/Disable preview mode for the Broker CRM',
  [Feature.NeedAPro]: 'Enable/Disable referrals to professional tradesman',
  [Feature.MovingCompany]: 'Enable/Disable referrals to moving companies',
  [Feature.ConveyancingClientPortal]: 'Enable/Disable the ability to refer clients to a conveyancer',
  [Feature.CreditReportCp]: 'Enable/Disable credit report summary in the client portal', // Depreciated
  [Feature.Dashboard3Cp]: 'Enable/Disable dashboard 3.0 in the client portal',
  [Feature.TurnOffReportProblem]: 'Turn On/Off problem reporting to Zandesk feature',
  [Feature.HomebuyersChecklist]: 'Enable/Disable homebuyers checklist',
  [Feature.AccountingSearchMode]: 'Enable/Disable preview mode for new accounting search',
  [Feature.CaseEconomics]: 'Enable/Disable case economics',
  [Feature.ReportCommissions]: 'Enable/Disable commissions section on reports tab',
  [Feature.Dashboards]: 'Enable/Disable access to the new search-powered dashboards',
  [Feature.HidePredictedRevenue]: 'Display/Hide the predicted revenue field on case items on the case board',
  [Feature.ShowComplianceView]: 'Display/Hide the compliance view tab',
  [Feature.HideLandRegistryPurchase]: 'Display/hide the buy button for land registry property data',
  [Feature.AddingManualGI]: 'Enable/Disable adding manual GI for mortgage cases (CTO Approval Required)',
  [Untracked.ARIsTradingStyle]:
    'Enable/Disable AR is Trading Style, which in turn affects the wording on certain templates.',
  [Untracked.ARIsSubdivision]:
    'Enable/Disable AR is Subdivision, which in turn affects the wording on certain templates.',
  [Club.FPG]: 'Enable/Disable FPG club',
  [Club.PMS13]: 'Enable/Disable PMS 13 club',
  [Club.PMSIPFA]: 'Enable/Disable PMS IPFA club',
  [Club.PMSEXT]: 'Enable/Disable PMS EXT club',
  [Club.RightMortgage]: 'Enable/Disable Right Mortgage club',
  [Club.MMD]: 'Enable/Disable MMD club',
  [Club.CONNELLS]: 'Enable/Disable connells club',
  [Feature.Payments]: 'Enable/Disable payments through GoCardless',
  [Feature.CaseSidebarDrawer]: 'Enable/Disable case sidebar drawer',
  [Feature.StandaloneGI]: 'Enable/Disable the creation of standalone GI case',
  [Feature.EnhancedAutoRefer]: 'Enable/Disable access to auto-refer for Conveyancing and Wills',
  [Feature.HideOutOfPanelProcs]: 'Enable/Disable the hiding of out of panel proc fees in sourcing',
  [Feature.HomeInOne]: 'Enable/Disable GI sourcing through Home in One',
  [Feature.PrivateMedicalInsurance]: 'Enable/Disable private medical insurance',
  [Feature.CommissionOptions]: 'Enable/Disable commission options',
  [Feature.MultiOrgReport]: 'Enable/Disable other reports new endpoint',
  [Feature.ComplianceDashboardNavLinks]: 'Enable/Disable compliance dashboard navs',
  [Feature.ConveyancingScotland]:
    'Enable/Disable access to Smoove in Scotland. Also requires conveyancing to be enabled.',
  [Feature.AdvancedPermissions]: 'Enable/Disable advanced permissions',
  [Feature.XlsReport]: 'Enable/Disable XLS report',
  [Feature.DataSecurityReport]: 'Enable/Disable data security report',
  [Feature.AllowARToggleIncludeDirect]: 'Enable/Disable Allow ARs to toggle Include Direct when sourcing',
}

export const flags = {
  [FlagType.Feature]: {
    [Feature.Fullstory]: configFlags.get(Feature.Fullstory) || false,
    [Feature.MI]: configFlags.get(Feature.MI) || true,
    //HideClientList is always intended to be a per-org setting so cannot be set using global config.
    [Feature.HideClientList]: false,
    [Feature.NewProtectionSourcing]: configFlags.get(Feature.NewProtectionSourcing) || true,
    [Feature.AddIntroducer]: configFlags.get(Feature.AddIntroducer) || true,
    [Feature.InviteToScheduleAppointment]: configFlags.get(Feature.InviteToScheduleAppointment) || false,
    [Feature.Conveyancing]: configFlags.get(Feature.Conveyancing) || true,
    [Feature.KbaFlow]: configFlags.get(Feature.KbaFlow) || false,
    [Feature.Network]: false,
    [Feature.BulkIntroducers]: configFlags.get(Feature.BulkIntroducers) || false,
    [Feature.Integrations]: configFlags.get(Feature.Integrations) || false,
    [Feature.CreditReportCp]: configFlags.get(Feature.CreditReportCp) || true,
    [Feature.Dashboard3Cp]: configFlags.get(Feature.Dashboard3Cp) || false,
    [Feature.TurnOffReportProblem]: configFlags.get(Feature.TurnOffReportProblem) || false,
    [Feature.Lender]: {
      [LenderFlag.Santander]: false,
      [LenderFlag.NatWest]: false,
      [LenderFlag.Nationwide]: true,
    },
    [Feature.ReferWills]: configFlags.get(Feature.ReferWills) || false,
    [Feature.Surveying]: true,
    [Feature.SurveyingCp]: configFlags.get(Feature.SurveyingCp) || false,
    [Feature.SurveysNew]: configFlags.get(Feature.SurveysNew) || false,
    [Feature.PreviewMode]: configFlags.get(Feature.PreviewMode) || false,
    [Feature.NeedAPro]: configFlags.get(Feature.NeedAPro) || false,
    [Feature.MovingCompany]: configFlags.get(Feature.MovingCompany) || false,
    [Feature.ConveyancingClientPortal]: configFlags.get(Feature.ConveyancingClientPortal) || false,
    [Feature.HomebuyersChecklist]: configFlags.get(Feature.HomebuyersChecklist) || true,
    [Feature.AccountingSearchMode]: configFlags.get(Feature.AccountingSearchMode) || false,
    [Feature.HideLandRegistryPurchase]: configFlags.get(Feature.HideLandRegistryPurchase) || false,
    [Feature.CaseEconomics]: configFlags.get(Feature.CaseEconomics) || false,
    [Feature.ReportCommissions]: configFlags.get(Feature.ReportCommissions) || false,
    [Feature.Dashboards]: false,
    [Feature.HidePredictedRevenue]: configFlags.get(Feature.HidePredictedRevenue) || false,
    [Feature.ShowComplianceView]: configFlags.get(Feature.ShowComplianceView) || false,
    [Feature.Payments]: configFlags.get(Feature.Payments) || false,
    [Feature.CaseSidebarDrawer]: configFlags.get(Feature.CaseSidebarDrawer) || false,
    [Feature.AddingManualGI]: configFlags.get(Feature.AddingManualGI) || false,
    [Feature.StandaloneGI]: configFlags.get(Feature.StandaloneGI) || false,
    [Feature.EnhancedAutoRefer]: configFlags.get(Feature.EnhancedAutoRefer) || false,
    [Feature.PrivateMedicalInsurance]: configFlags.get(Feature.PrivateMedicalInsurance) || false,
    [Feature.HideOutOfPanelProcs]: configFlags.get(Feature.HideOutOfPanelProcs) || false,
    [Feature.HomeInOne]: configFlags.get(Feature.HomeInOne) || false,
    [Feature.CommissionOptions]: configFlags.get(Feature.CommissionOptions) || true,
    [Feature.MultiOrgReport]: configFlags.get(Feature.MultiOrgReport) || false,
    [Feature.ComplianceDashboardNavLinks]: configFlags.get(Feature.ComplianceDashboardNavLinks) || false,
    [Feature.AdvancedPermissions]: configFlags.get(Feature.AdvancedPermissions) || false,
    [Feature.ConveyancingScotland]: configFlags.get(Feature.ConveyancingScotland) || false,
    [Feature.XlsReport]: configFlags.get(Feature.XlsReport) || false,
    [Feature.DataSecurityReport]: configFlags.get(Feature.DataSecurityReport) || false,
    [Feature.AllowARToggleIncludeDirect]: configFlags.get(Feature.AllowARToggleIncludeDirect) || false,
  },
  [FlagType.Automation]: {
    [Automation.InviteClient]: false,
  },
  [FlagType.Untracked]: {
    [Untracked.ARIsTradingStyle]: configFlags.get(Untracked.ARIsTradingStyle) || false,
    [Untracked.ARIsSubdivision]: configFlags.get(Untracked.ARIsSubdivision) || false,
  },
  [FlagType.Club]: {
    [Club.PMS13]: false,
    [Club.PMSIPFA]: false,
    [Club.PMSEXT]: false,
    [Club.FPG]: false,
    [Club.RightMortgage]: false,
    [Club.MMD]: false,
    [Club.CONNELLS]: false,
  },
}

// Used for testing purposes
export const flagsAllEnabled = {
  [FlagType.Feature]: {
    [Feature.Fullstory]: true,
    [Feature.MI]: true,
    [Feature.HideClientList]: true,
    [Feature.NewProtectionSourcing]: true,
    [Feature.AddIntroducer]: true,
    [Feature.InviteToScheduleAppointment]: true,
    [Feature.Conveyancing]: true,
    [Feature.KbaFlow]: true,
    [Feature.Network]: true,
    [Feature.BulkIntroducers]: true,
    [Feature.Integrations]: true,
    [Feature.CreditReportCp]: false,
    [Feature.Dashboard3Cp]: false,
    [Feature.TurnOffReportProblem]: false,
    [Feature.Lender]: {
      [LenderFlag.Santander]: false,
      [LenderFlag.NatWest]: false,
      [LenderFlag.Nationwide]: true,
    },
    [Feature.Surveying]: true,
    [Feature.SurveyingCp]: true,
    [Feature.SurveysNew]: true,
    [Feature.ReferWills]: false,
    [Feature.Dashboards]: true,
    [Feature.PreviewMode]: false,
    [Feature.NeedAPro]: false,
    [Feature.MovingCompany]: false,
    [Feature.ConveyancingClientPortal]: false,
    [Feature.HomebuyersChecklist]: true,
    [Feature.AccountingSearchMode]: false,
    [Feature.CaseEconomics]: false,
    [Feature.HidePredictedRevenue]: false,
    [Feature.ShowComplianceView]: false,
    [Feature.HideLandRegistryPurchase]: false,
    [Feature.Payments]: true,
    [Feature.CaseSidebarDrawer]: true,
    [Feature.AddingManualGI]: true,
    [Feature.StandaloneGI]: true,
    [Feature.ReportCommissions]: false,
    [Feature.EnhancedAutoRefer]: false,
    [Feature.HideOutOfPanelProcs]: false,
    [Feature.HomeInOne]: true,
    [Feature.PrivateMedicalInsurance]: false,
    [Feature.CommissionOptions]: false,
    [Feature.MultiOrgReport]: false,
    [Feature.ComplianceDashboardNavLinks]: false,
    [Feature.ConveyancingScotland]: true,
    [Feature.AdvancedPermissions]: false,
    [Feature.XlsReport]: true,
    [Feature.DataSecurityReport]: true,
    [Feature.AllowARToggleIncludeDirect]: false,
  },
  [FlagType.Automation]: {
    [Automation.InviteClient]: false,
  },
  [FlagType.Untracked]: {
    [Untracked.ARIsTradingStyle]: false,
    [Untracked.ARIsSubdivision]: false,
  },
  [FlagType.Club]: {
    [Club.PMS13]: true,
    [Club.PMSIPFA]: true,
    [Club.PMSEXT]: false,
    [Club.FPG]: true,
    [Club.RightMortgage]: true,
    [Club.MMD]: true,
    [Club.CONNELLS]: true,
  },
}

export type Flags = InferType<typeof flags>

export const { useFlag, useFlags, Flag, FlagProvider } = createFlags<Flags>()
