import { ErrorResponse } from '@apollo/client/link/error'
import { captureException } from '@sentry/react'
import { GraphQLFormattedError } from 'graphql'
import { isNil } from 'lodash'

import { LutherException } from '@acre/graphql'

import ErrorProvider from '../errorHandler/ErrorProvider'
import { isForbiddenError, statusCodes } from './links.helpers'

const isLutherException = (errors: readonly GraphQLFormattedError[]) => {
  return errors[0].extensions?.name === 'LutherException'
}

const lutherErrorLink = ({ response, graphQLErrors, operation }: ErrorResponse) => {
  // We're not interested in Unauthorized errors, because those
  // are handled by the authRedirectErrorLink
  if (
    graphQLErrors?.length &&
    !graphQLErrors.some((error) => isForbiddenError(error, 'graphql')) &&
    isLutherException(graphQLErrors)
  ) {
    const graphQLError = graphQLErrors[0]
    const error = graphQLError as LutherException
    const statusCode = error.statusCode
    const link = document.createElement('a')
    link.href = error.url

    error.message = `Request to ${link.pathname} failed with code ${statusCode}`
    //@ts-ignore
    if (!statusCodes.includes(statusCode) && isNil(window?.Cypress)) {
      captureException(error, {
        extra: {
          url: error.url,
          status: error.statusCode,
          message: error.message,
        },
        contexts: {
          exception: {
            id: error.id,
            type: error.type,
            description: error.description,
          },
          graphql: {
            operation: operation.operationName,
            variables: JSON.stringify(operation.variables, null, 2),
          },
        },
      })
    }
    console.error(graphQLErrors)
    if (response) response.errors = []

    // Prevent toast with error showing when there are insufficient permissions to get the source of ledgers
    // This permission issue should be fixed when these three BE ticekts are completed (at which point the if statement below can be removed):
    // https://acreplatforms.atlassian.net/browse/RD-8347
    // https://acreplatforms.atlassian.net/browse/RD-8349
    // https://acreplatforms.atlassian.net/browse/RD-8350
    if (
      graphQLError?.path?.includes('getLedgers') &&
      graphQLError?.path?.includes('sourceId') &&
      graphQLError?.message === 'Request failed with status code 403'
    ) {
      return
    }

    ErrorProvider.showError(graphQLError)
  }
}

export default lutherErrorLink
